import React, { useState, useEffect, useRef } from "react";
import {
  create, createOptions, DocumentFormat
} from 'devexpress-richedit';
import 'devextreme/dist/css/dx.light.css';
import 'devexpress-richedit/dist/dx.richedit.css';

import { getFile, saveFile } from "../../../services/backend/EditorServices";
import { debugLog } from "../../../functions";
import configOptions from "./options";
import template from "./template";
import { useSelector } from "react-redux";

const RichEditor = ({
  file,
  tags,
  dataSource,
  height,
  readOnly = false,
  noRibbon = false,
  onSave,
  itemEvents,
  setValidatedEditor,
  setEditorRef,
  onDocumentLoaded,
  onDocumentChanged
}) => {
  const richRef = useRef();
  const user = useSelector((state) => state.user.value);
  const [rich, setRich] = useState();

  /**
   * Saving base64 string in Storage
   */
  const handleSaving = async (editor, evt) => {
    try {
      const url = await saveFile(file.path, evt.base64);
      editor.hasUnsavedChanges = false;

      if (onSave) onSave(url, editor, evt);
      handleDocumentLoaded(editor);
    } catch (error) {
      debugLog('Upload failed:', error);
    }
  };

  /**
   * Validate the editor tags
   */
  const handleValidateEditor = (editor) => {
    if (!setValidatedEditor) return;
    const fields = editor.document.fields._subDocument.fields;
    let validate = false;
    if (editor.hasUnsavedChanges) validate = 'El documento no ha sido guardado';

    fields.forEach((field) => {
      const tag = editor.document.getText(field.getCodeInterval()).split(' ')[1];

      if (tags.includes(tag) && (!dataSource[tag] || dataSource[tag] === `<<${tag}>>`)) {
        validate = `Hay etiquetas en el documento que no tienen valor: <<${tag}>>`;
      }
    });
      
    setValidatedEditor(validate);
  };

  /**
   * Handler when document is loaded
   */
  const handleDocumentLoaded = (editor) => {
    handleValidateEditor(editor);
    if (onDocumentLoaded) onDocumentLoaded(editor);
  };

  /**
   * Handler when document is loaded
   */
  const handleDocumentChanged = (editor) => {
    if (setValidatedEditor && editor.hasUnsavedChanges) setValidatedEditor('El documento no ha sido guardado');
    if (onDocumentChanged) onDocumentChanged(editor);
  };

  /**
   * Loading Editor and content
   */
  const loadEditorAsync = async () => {
    if (rich || richRef.current.classList.contains('dxreControl')) return;

    const options = createOptions();
    configOptions.setOptions(options, itemEvents, user);
    configOptions.setEvents(options, 'saving', handleSaving);
    configOptions.setEvents(options, 'documentLoaded', handleDocumentLoaded);
    configOptions.setEvents(options, 'documentChanged', handleDocumentChanged);

    options.readOnly = readOnly;
    options.width = '100%';
    options.height = height || 'calc(100vh - 65px)';
    options.mailMerge.dataSource = [];
    if (noRibbon) options.ribbon = { visible: false };
    
    const tRich = create(document.getElementById("richEdit"), options);

    let content;
    if (file.url) content = await getFile(file.url);
    else {
      content = template;
      setTimeout(() => tRich.hasUnsavedChanges = true, 1000);
    }

    tRich.openDocument(content, 'DocumentName', DocumentFormat.OpenXml);
    if (itemEvents) tRich.royal = { events: { ...itemEvents } };
    setRich(tRich);
    if (setEditorRef) setEditorRef(tRich);
  }
  
  useEffect(() => {
    loadEditorAsync();
  }, []); // eslint-disable-line

  useEffect(() => {
    if (rich) {
      rich.mailMergeOptions._native.dataSource._items = [dataSource];
      handleValidateEditor(rich);
    }
  }, [dataSource, rich]); // eslint-disable-line

  return (
    <div>
      <div ref={richRef} id="richEdit"></div>
    </div>
  );
};

export default RichEditor;
